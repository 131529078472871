.dp-button {
    position: relative;
    cursor: pointer;
}
.dp-button input {
    font-size: 14px;
    cursor: pointer;
}
.dropdown {
    position: absolute;
    z-index: 2;
    top: 38px;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #E2E5E9;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
}
.dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.dropdown ul li {
    padding: 5px 8px;
    border-bottom: 1px solid #E2E5E9;
    cursor: pointer;
}
.dropdown ul li:last-of-type {
    border-bottom: 0;
}
.dropdown ul li:before {
    display: none;
}