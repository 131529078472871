.react-datepicker__input-container input:focus {
  outline: none;
  border: 1.5px solid #f37022;
}

.react-datepicker__input-container input {
  width: 100% !important;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.react-datepicker__input-container input {
  background: url("../../assets/calenderIcon.png") no-repeat right;
  background-position: 92%;
  background-size: 15px;
  /* width: 300px; */
  height: 36px;
  padding: 0 10px;
  font-size: 14px;
}

.react-datepicker__input-container {
  /* width: 200px !important; */
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker {
  /* position: absolute !important;
  right: -180px !important; */
}

.some-custom-class2 {
  position: absolute !important;
  right: 130px !important;
}
.some-custom-class1 {
  position: absolute !important;
  left: 15px !important;
}

.react-datepicker-ignore-onclickoutside {
  width: 100% !important;
}
.sample-request .form-group {
  margin-top: 16px;
  border: 1px solid #d1d5db;
  padding: 16px;
  border-radius: 8px;
}
.sample-request .field {
  margin-bottom: 16px;
}
.sample-request .field:last-child {
  margin-bottom: 0;
}
.sample-request .field label {
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
  color: #777;
}
.dealer-info .field {
  margin-bottom: 16px;
}

.error-field {
  border-color: red;
  color: red;
}
.error-field::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: red;
}
.error-field::-moz-placeholder { /* Firefox 19+ */
  color: red;
}
.error-field:-ms-input-placeholder { /* IE 10+ */
  color: red;
}
.error-field:-moz-placeholder { /* Firefox 18- */
  color: red;
}