@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "proxima-nova"; /*Can be any text*/
  src: url("./assets/font/Proxima\ Nova\ Font.otf") format("opentype");
}
/* local("AirbnbCerealLight"), */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "proxima-nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100dvh;
  /* overflow: hidden; */
  scroll-behavior: smooth;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: "proxima-nova", sans-serif;
}

* {
  font-family: "proxima-nova", sans-serif;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding-left: 10px;
  background: #f1f5f8;
  /* background: transparent; */
  cursor: pointer;
  border-radius: 10px;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background: #bdc2c7;
  border-radius: 10px;
  transition: all linear 0.5s;
  border: 2px solid transparent;
  background-clip: padding-box;
  cursor: pointer;
}
::-webkit-scrollbar-hover,
::-webkit-scrollbar-thumb:hover {
  background: #ec691f;
  border: 3px solid #ec691f;
  border: 0;
  cursor: pointer;
}
.btn {
  border-radius: 8px;
  padding: 0 16px;
  border: 1px solid #e2e5e9;
  color: #757a7f;
  width: 116px;
  height: 36px;
  transition: all linear .3s;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.btn-primary {
  font-size: 14px;
  background-color: #ec691f;
  color: #fff;
  font-weight: 400;
}