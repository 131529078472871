.drawer {
    width: 340px;
    background-color: #fff;
}
.drawer-heading {
    color: #212529;
    font-size: 22px;
    line-height: 36px;
    font-weight: 700;
}
.drawer-heading .dropdown {
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #ccc;
    padding: 4px 8px;
    height: 30px;
    line-height: normal;
    border-radius: 8px;
}
.drawer-subheading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 20px;
}
.drawer-subheading img {
    margin-right: 8px;
}
.drawer-subheading img.delete {
    margin-left: auto;
    margin-right: 0;
}
.drawer-header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    width: 100%;
}
.drawer-header .drawer-heading {
    padding: 25px 16px 27px;
    width: 100%;
}
.drawer-body {
    padding: 0 16px;
    min-height: calc(100vh - 174px);
    width: 100%;
}
.drawer-body .drawer-subheading:nth-child(n+2) {
    margin-top: 34px;
}
.drawer-footer {
    padding: 25px 16px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.MuiPaper-root {
    /* width: 100%; */
    overflow-y: scroll !important;
    display: block !important;
}
.MuiAlert-root {
    overflow-y: visible !important;
    display: flex !important;
}
.MuiPaper-root textarea::placeholder {
    color: #9FA3A9;
}
.MuiPaper-root input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #9FA3A9;
}
.MuiPaper-root input::-moz-placeholder { /* Firefox 19+ */
    color: #9FA3A9;
}
.MuiPaper-root input:-ms-input-placeholder { /* IE 10+ */
    color: #9FA3A9;
}
.MuiPaper-root input:-moz-placeholder { /* Firefox 18- */
    color: #9FA3A9;
}

.drawer-form label {
    color: #61666A;
    font-size: 14px;
}
.drawer-form .property {
    max-width: 200px;
    width: 100%;
}
.drawer-form .property input,
.drawer-form .property textarea,
.drawer-form .property select {
    border: 1px solid #d1d5db;
    width: 100%;
    border-radius: 8px;
    text-align: left;
    padding: 5px 8px;
    outline: none;
}
.drawer-form .property select option {
    text-align: left;
}
.drawer-form .item-wrapper {
    border-top: 1px solid #d1d5db;
    padding-top: 16px;
    margin-bottom: 16px;
}
.drawer-form .item-wrapper:first-child {
    border-top-color: transparent;
    padding-top: 0;
}