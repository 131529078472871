/* swiper-slide swiper-slide-active */

.swiper-slide {
  /* height: 132px; */
  /* width: 100px; */
  /* border-top: 3px solid #337c5f; */
  /* border-radius: 2px; */
  /* margin-top: 5px; */
  position: relative;
  margin-bottom: 10px;
}
/* .swiper-slide:before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: transparent;
  position: absolute;
  top: -5px;
  left: 0;
  border-radius: 3px 3px 0 0;
} */
.swiper-slide .line {
  display: none;
}
.swiper-slide-next .line {
  display: block;
}

.swiper-slide-next {
  /* -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px 1px #999; */

  /* border-radius: 4px; */
}
.swiper-slide-active {
  /* height: 138px; */
  outline: none;
  /* border: 1px solid rgb(223, 221, 221); */
  /* box-shadow: -3px -3px 7px rgb(94 104 121 / 7%),
    3px 3px 5px rgb(94 104 121 / 30%); */
  /* box-shadow: 0 0px 24px rgba(0, 0, 0, 0.1); */
  /* box-shadow: -3px -3px 7px rgb(94 104 121 / 7%),
    3px 3px 5px rgb(94 104 121 / 30%); */
  /* -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px 1px #999;

  border-radius: 4px; */
}
.swiper-slide-next h3 {
  /* color: #f26401; */
}
/* .swiper-slide-active .line {
  background-color: black;
} */
.swiper-slide-active:before {
  background-color: #fff;
}
.swiper-slide-next {
  /* border-top: 3px solid #7f323e; */
}

/* date picker style */

/* .datePicker {
  border: 1px solid gray;
  padding: 2px 3px;
  width: 50%;
}

.datePicker:focus {
  border: 1px solid #f37022 !important;
} */

/* .react-datepicker__input-container input:focus {
  outline: none;
  border: 1.5px solid #f37022;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
} */
.line {
  /* width: 2px; */
}

.swiper-scrollbar-drag {
  background-color: #d2d1d1;
  height: 3px;
  opacity: 1;
}
.swiper-scrollbar,
.swiper-scrollbar-horizontal {
  opacity: 1 !important;
}
