.attendance-modal {
    margin-top: 8px;
}
.attendance-modal .field {
    margin-bottom: 12px;
}
.attendance-modal .field label {
    font-size: 14px;
    display: block;
    text-align: left;
    margin-bottom: 4px;
}
.attendance-modal .field input {
    margin-top: 0;
}
.ss-remarks-wrapper {
    border: 1px solid #9ca3af;
    padding: 16px;
    border-radius: 4px;
    margin-top: 16px;
}
.ss-remarks-wrapper .ss-info label {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    margin-bottom: 8px;
}
.ss-remarks-wrapper .ss-info input {
    margin-right: 6px;
}
.ss-remarks-wrapper .competitor-product-name input {
    padding: 4px 8px;
}