.ss-leads-info {
    padding: 0 16px;
    margin-top: 12px;
}
.ss-leads-info ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.ss-leads-info ul li:before {
    display: none;
}
.ss-leads-info ul li {
    font-size: 14px;
    padding: 2px 0;
    display: flex;
    flex-wrap: wrap;
}
.ss-leads-info .list-heading {
    margin-right: 12px;
    width: 90px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.ss-leads-info .desc {
    color: #555555;
    width: calc(100% - 102px);
}