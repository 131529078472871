.accordian {
    padding: 8px 0;
}
.accordian ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.accordian ul li:before {
    display: none;
}
.accordian .list-heading {
    font-size: 16px;
    color: #f37022;
    padding: 8px 16px;
    position: relative;
}
.accordian .list-heading:before {
    content: '';
    display: block;
    border: solid #f37022;
    border-width: 2px 2px 0 0;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
}
.accordian .desc {
    padding: 0 16px;
    margin: 10px 0;
}
.accordian .desc .parent {
    position: relative;
    padding-left: 16px;
}
.accordian .desc p {
    font-size: 14px;
    line-height: 24px;
    padding: 4px 0;
}
.accordian .desc .child p {
    font-size: 14px;
    position: relative;
    padding-left: 32px;
}
.accordian .desc .parent:before,
.accordian .desc .child p:before {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border: solid #f37022;
    border-width: 0 2px 2px 0;
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
.accordian .desc .child p:before {
    left: 16px;
}