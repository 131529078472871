.custom-accodian ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.custom-accodian ul li:before {
    display: none;
}
.custom-accodian ul li {
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    cursor: pointer;
}
.custom-accodian ul li:last-of-type {
    margin-bottom: 0;
}
.custom-accodian .accordian-heading {
    cursor: pointer;
    /* margin-bottom: 20px; */
    font-size: 16px;
    position: relative;
}
.custom-accodian .accordian-heading:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border: solid #f37022;
    border-width: 2px 2px 0 0;
    transform: rotate(135deg);
    position: absolute;
    right: 0;
    top: 6px;
    /* bottom: 0; */
    margin: auto;
}
.custom-accodian .accordion-body {
    display: none;
}
.custom-accodian ul li.show .accordian-heading:after {
    transform: rotate(-45deg);
}
.custom-accodian ul li.show .accordion-body {
    display: block;
    padding: 16px 0;
}
.custom-accodian .accordion-body .form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.custom-accodian .accordion-body .field {
    width: 100%;
    margin-bottom: 4%;
}
.custom-accodian .accordion-body .field label {
    margin-bottom: 8px;
    display: block;
    font-size: 14px;
    color: #777;
}
.imprest {
    margin-top: 16px;
}
.imprest .subheading {
    font-size: 16px;
    width: 100%;
    margin-bottom: 8px;
}
.imprest .form-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 16px;
}
.imprest input {
    width: 100%;
}
.imprest label {
    color: #777;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 20px;
}
.imprest label input {
    margin-right: 4px;
    width: auto;
}
.error-field {
    border-color: red;
}
.error-field::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: red;
}
.error-field::-moz-placeholder { /* Firefox 19+ */
    color: red;
}
.error-field:-ms-input-placeholder { /* IE 10+ */
    color: red;
}
.error-field:-moz-placeholder { /* Firefox 18- */
    color: red;
}