.leads-info-wrapper{
    padding:16px;
    padding-top: 0px;
}
.leads-info-item{
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.leads-info-item label{
    color:#8d7d7d ;
    display: inline-block;
    width: 90px;
}
.leads-item-column{
    display: flex;
    flex-direction: column;
}
.leads-item-column span{
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.leads-item-column span:first-child{
    color:#8d7d7d ;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.seperator-line{
    width: 100%;
    height: 1px;
    background-color:#e5dddd ;
}
.custom-chip{
    font-size: 10px;
    border-radius: 20px;
    padding:4px 8px;
    width:47px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
}
.lead-remark-btn{
    border: 1px solid #3b82f6;
    font-size: 10px;
    width: 77px;
    display: flex;
    justify-content: center;
    align-items: center;
}
