.summary-report {
    margin-top: 12px;
}
.summary-report ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.summary-report ul li:before {
    display: none;
}
.summary-report ul li {
    font-size: 14px;
    padding: 2px 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
}
.summary-report ul li:last-of-type {
    margin-bottom: 0;
}
.summary-report .list-heading {
    margin-right: 12px;
    width: 100%;
}
.summary-report .desc {
    color: #555555;
    width: calc(100%);
}